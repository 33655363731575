import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2f2e786a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ultimate_beneficary_card" }
const _hoisted_2 = { class: "top_title" }
const _hoisted_3 = {
  key: 1,
  class: "icon text_icon"
}
const _hoisted_4 = { class: "company_title" }
const _hoisted_5 = { class: "content_wrap" }
const _hoisted_6 = { class: "proportion" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_image = _resolveComponent("van-image")!
  const _component_v_chart = _resolveComponent("v-chart")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.itemData?.logo)
        ? (_openBlock(), _createBlock(_component_van_image, {
            key: 0,
            width: "35",
            height: "35",
            src: _ctx.itemData.logo,
            class: "icon"
          }, null, 8, ["src"]))
        : (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.itemData?.name.slice(0, 1)), 1)),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.itemData?.name), 1)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_v_chart, {
          class: "chart",
          option: _ctx.piePption
        }, null, 8, ["option"])
      ])
    ])
  ]))
}