
import { computed, defineComponent } from 'vue'
import { CollapseItem, Image as VanImage } from 'vant'

export default defineComponent({
  name: 'customerCollapseItem',
  components: {
    [CollapseItem.name]: CollapseItem,
    [VanImage.name]: VanImage
  },
  props: {
    active: Array,
    name: String,
    title: String,
    icon: String,
    iconStyle: String
  },
  setup(props) {
    const activeItem = computed(() => {
      return props.active?.includes(props.name)
    })
    const itemTitle = computed(() => {
      return props.title
    })
    return {
      activeItem,
      itemTitle
    }
  }
})
