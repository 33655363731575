import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "customer_basic_info" }
const _hoisted_2 = {
  key: 0,
  class: "no_data"
}
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Cell = _resolveComponent("Cell")!
  const _component_van_cell_group = _resolveComponent("van-cell-group")!
  const _component_CustomerCpllapseVue = _resolveComponent("CustomerCpllapseVue")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_CustomerCpllapseVue, {
      active: _ctx.activeName,
      name: "1",
      title: "基本信息",
      icon: "/img/icons/basicInfo.png",
      iconStyle: "width:16.93px;height:12.78px;"
    }, {
      content: _withCtx(() => [
        (_ctx.customerInfo === '{}')
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, "无"))
          : (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_van_cell_group, { class: "cell_group" }, {
                default: _withCtx(() => [
                  _createVNode(_component_Cell, {
                    title: "姓名",
                    value: _ctx.customerInfo.name,
                    labelWidth: 89.5,
                    "value-color": "#5684FF"
                  }, null, 8, ["value", "labelWidth"]),
                  _createVNode(_component_Cell, {
                    title: "手机号",
                    value: _ctx.customerInfo.mobile,
                    "label-width": 89.5
                  }, null, 8, ["value", "label-width"]),
                  _createVNode(_component_Cell, {
                    title: "身份证号",
                    value: _ctx.customerInfo.idCard,
                    labelWidth: 89.5
                  }, null, 8, ["value", "labelWidth"]),
                  _createVNode(_component_Cell, {
                    title: "与企业关系",
                    value: _ctx.businessRelation,
                    labelWidth: 89.5
                  }, null, 8, ["value", "labelWidth"])
                ]),
                _: 1
              }),
              _createVNode(_component_van_cell_group, { class: "cell_group" }, {
                default: _withCtx(() => [
                  _createVNode(_component_Cell, {
                    title: "手机号稳定使用时长",
                    value: _ctx.telPeriod,
                    labelWidth: 127
                  }, null, 8, ["value"]),
                  _createVNode(_component_Cell, {
                    title: "手机号是否正常使用",
                    value: _ctx.useStatus,
                    "label-width": 127
                  }, null, 8, ["value"]),
                  _createVNode(_component_Cell, {
                    title: "是否为本人手机号",
                    value: _ctx.isSelf,
                    "label-width": 127
                  }, null, 8, ["value"])
                ]),
                _: 1
              })
            ]))
      ]),
      _: 1
    }, 8, ["active"])
  ]))
}