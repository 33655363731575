import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-38b01f16"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "title_wrap" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_image = _resolveComponent("van-image")!
  const _component_van_collapse_item = _resolveComponent("van-collapse-item")!

  return (_openBlock(), _createBlock(_component_van_collapse_item, {
    name: _ctx.name,
    class: "customer_collapse_item"
  }, {
    title: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_van_image, {
          src: _ctx.icon,
          class: "icon",
          style: _normalizeStyle(_ctx.iconStyle)
        }, null, 8, ["src", "style"]),
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.itemTitle), 1)
      ])
    ]),
    "right-icon": _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.activeItem ? 'right_icon right_icon_active' : 'right_icon')
      }, [
        _createVNode(_component_van_image, {
          width: "7px",
          height: "5px",
          src: "/img/icons/row.png"
        })
      ], 2)
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_3, [
        _renderSlot(_ctx.$slots, "content", {}, undefined, true)
      ])
    ]),
    _: 3
  }, 8, ["name"]))
}