
import { defineComponent, reactive, ref } from 'vue'
import { Button, Form, Field, CellGroup, Image as VanImage, Notify } from 'vant'
import CancelDialogComponent from './components/cancelDialog.vue'
import { personalCheck, preparePersonalPortrait, sendSmsCode } from '@/service/personalService'

interface item {
  name: string
  value: string
}

export default defineComponent({
  name: 'CompletInfo',
  components: {
    [Button.name]: Button,
    [Form.name]: Form,
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
    [VanImage.name]: VanImage,
    CancelDialogComponent,
    [Notify.Component.name]: Notify.Component
  },
  setup() {
    sessionStorage.removeItem('personalData')
    const value = ref('')
    const name = ref('')
    const mobile = ref('')
    const verifyCode = ref('')
    const verifyId = ref('')
    const idCard = ref('')
    const interval = ref(0)
    const timer = ref(0)
    const selectIndex = ref(0)
    const businessRelationship = ref('1')
    const verificationId = ref('')
    const errMsg = ref('')
    const selects = reactive([
      { name: '法定代表人', value: '1' },
      { name: '实际控制人', value: '2' },
      { name: '股东', value: '3' },
      { name: '高管', value: '4' }
    ])
    const show = ref(false)
    const phoneValid = /^[1][3-9][0-9]{9}$/
    const idValid = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/
    const nameValid = (val: string) => {
      if (val.length < 2 || val.length > 32) {
        return false
      }
    }

    const codeValid = (val: string) => {
      if (val.length !== 6) {
        return false
      }
    }

    const loading = ref(false)

    return {
      value,
      name,
      mobile,
      verifyCode,
      idCard,
      interval,
      timer,
      selects,
      selectIndex,
      show,
      verifyId,
      phoneValid,
      idValid,
      businessRelationship,
      verificationId,
      nameValid,
      codeValid,
      loading,
      errMsg
    }
  },
  created() {
    sessionStorage.removeItem('personalData')
  },
  methods: {
    // 获取验证码
    validMobile() {
      if (!this.loading) {
        let el: any = this.$refs.form as HTMLElement
        el.validate('手机号')
          .then(() => {
            this.loading = true
            this.sendCode()
          })
          .catch((err: any) => {
            console.log(err)
            return Notify({ type: 'danger', message: '请先输入正确的手机号' })
          })
      }
    },
    async sendCode() {
      try {
        await this.getSmsCode()
        this.timer = 59
        this.loading = false
        this.interval = setInterval(() => {
          if (this.timer > 1) {
            this.timer--
          } else {
            this.timer = 0
            clearInterval(this.interval)
            this.interval = 0
          }
        }, 1000)
      } catch (err) {
        console.log(err)
      }
    },
    // 跳过
    skip() {
      this.$router.push('/report')
    },
    // 选择企业关系
    selectItem(item: item) {
      this.selectIndex = Number(item.value) - 1
      this.businessRelationship = item.value
    },
    // 提交
    onSubmit() {
      let el: any = this.$refs.form as HTMLElement
      el.validate()
        .then(() => {
          if (this.verifyId) {
            this.postData()
          } else {
            return Notify({ type: 'danger', message: '请先获取验证码' })
          }
        })
        .catch((err: any) => {
          const arr = []
          for (const child of err) {
            arr.push(child.name)
          }
          const str = arr.join('、')
          return Notify({ type: 'danger', message: `请正确填写${str}` })
        })
    },
    async postData() {
      try {
        const companyName = sessionStorage.getItem('enterprise')
        const params = {
          idCard: this.idCard,
          mobile: this.mobile,
          name: this.name,
          verifyCode: this.verifyCode,
          verifyId: this.verifyId,
          companyName: companyName,
          businessRelationship: this.businessRelationship
        }
        const res = await personalCheck(params)
        if (res.code !== '0') {
          this.errMsg = res.msg
          this.show = true
          return
        }
        this.verificationId = res.data
        const personalData = {
          businessRelationship: this.businessRelationship,
          verificationId: res.data
        }
        sessionStorage.setItem('personalData', JSON.stringify(personalData))
        this.preparePersonal()
        this.$router.push('/report')
      } catch (error: any) {
        console.log(error)
      }
    },
    async getSmsCode() {
      const params = {
        mobile: this.mobile
      }
      const res = await sendSmsCode(params)
      this.verifyId = res.data
    },
    async preparePersonal() {
      try {
        const params = {
          verificationId: this.verificationId,
          businessRelationship: this.businessRelationship
        }
        await preparePersonalPortrait(params)
      } catch (error) {
        console.log(error)
      }
    },
    // 关闭弹框
    cancelDialog() {
      this.show = false
    }
  }
})
