import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-768d0eda"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wave_loading" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(3, (index) => {
      return _createElementVNode("div", {
        key: index,
        class: "loading_item",
        style: _normalizeStyle(_ctx.style)
      }, null, 4)
    }), 64))
  ]))
}