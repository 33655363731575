/*
 * @Author: aoqing
 * @Date: 2021-11-28 10:50:36
 * @Last Modified by: aoqing
 * @Last Modified time: 2022-01-11 15:03:40
 */
import axios from 'axios'
import { Notify } from 'vant'
import md5 from 'js-md5'
import { getToken } from '@/utils/auth'

const service = axios.create({
  headers: {},
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 10000
})

// 请求拦截
service.interceptors.request.use(
  (config: any) => {
    const token = getToken()
    if (token) {
      config.headers['Authorization'] = `bearer ${token}`
    }
    if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'local' || process.env.NODE_ENV === 'uat') {
      if (config.method === 'post' || config.method === 'put') {
        // console.log('==========>')
        const onceData = config.data
        if (config.headers) {
          config.headers['runthink-sign'] = md5(JSON.stringify(onceData))
        } else {
          config.header = {
            ...config.headers,
            'runthink-sign': md5(JSON.stringify(onceData))
          }
        }
        // console.log('加密后====>', config)
      }
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// 响应拦截
service.interceptors.response.use(
  (response) => {
    const res = response.data
    // console.log('require===>', res)

    if (res.code !== '0' && res.code !== '1140160004' && res.code !== '1140160005' && res.code !== '1140160006' && res.code !== '1140112002') {
      Notify({
        message: res.msg || 'Error',
        type: 'warning',
        duration: 2 * 1000
      })
      return Promise.reject(res)
    } else {
      return res
    }
  },
  (error) => {
    // console.log('err===>' + error) // for debug
    // console.log('err')
    if (error.code !== '1140160004') {
      Notify({
        message: error.msg || '未知的错误信息',
        type: 'danger',
        duration: 2 * 1000
      })
      return Promise.reject(error)
    }
  }
)

export default service
