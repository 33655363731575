
import { ref, defineComponent, reactive } from 'vue'
import { Collapse, IndexBar, IndexAnchor } from 'vant'
import CompanyBasicInfo from './modules/CompanyBasicInfo/index.vue'
// import ShareHolder from './modules/ShareHolder/ShareHolder.vue'
import UltimateBeneficiary from './modules/UltimateBeneficiary/index.vue'
import NegativeJudicialInformation from './modules/NegativeJudicialInformation/NegativeJudicialInformation.vue'
import RiskWarningInfomation from './modules/RiskWarningInfomation/RiskWarningInfomation.vue'
import {
  ICompanyInfo,
  IShardHolder,
  IUltimate,
  IFaithless,
  IExecution,
  ICourtAnnouncement,
  ICourtRegister,
  IAbnormalOperation,
  IIllegal,
  IPunish,
  IEquityPledge,
  IChattelMortgage
} from '@/interfaces/companyReportType'
import ReportInterpretation from './modules/ReportInterpretation/index.vue'
import ScrollTabs from '@/components/scrollTabs/index.vue'
import { useRect, useScrollParent, useEventListener } from '@vant/use'
import type { IndexBarInstance } from 'vant'

export default defineComponent({
  name: 'CompanyPortrait',
  components: {
    [Collapse.name]: Collapse,
    [IndexBar.name]: IndexBar,
    [IndexAnchor.name]: IndexAnchor,
    CompanyBasicInfo,
    // ShareHolder,
    UltimateBeneficiary,
    NegativeJudicialInformation,
    RiskWarningInfomation,
    ReportInterpretation,
    ScrollTabs
  },
  props: {
    companyInfo: {
      type: Object as () => {
        basicInfoVo: ICompanyInfo
        shareholderVoList: IShardHolder[]
        ultimateBeneficiaryVoList: IUltimate[]
        faithlessVoList: IFaithless[]
        executionVoList: IExecution[]
        courtAnnouncementVoList: ICourtAnnouncement[]
        courtRegisterVoList: ICourtRegister[]
        abnormalOperationVoList: IAbnormalOperation[]
        illegalVoList: IIllegal[]
        punishVoList: IPunish[]
        equityPledgeVoList: IEquityPledge[]
        chattelMortgageVoList: IChattelMortgage[]
      },
      default: function () {
        return {
          basicInfoVo: {},
          shareholderVoList: [],
          ultimateBeneficiaryVoList: [],
          faithlessVoList: [],
          executionVoList: [],
          courtAnnouncementVoList: [],
          courtRegisterVoList: [],
          abnormalOperationVoList: [],
          illegalVoList: [],
          punishVoList: [],
          equityPledgeVoList: [],
          chattelMortgageVoList: []
        }
      },
      required: true
    }
  },
  methods: {},
  setup() {
    const activeNames = ref<string[]>([])
    const tabIndex = ref(-1)
    const fixed = ref(false)
    const scrolltabs = ref()
    const scrollParent = useScrollParent(scrolltabs)
    const indexBarRef = ref<IndexBarInstance>()
    const tabsData = reactive([
      {
        name: '基本信息'
      },
      {
        name: '最终受益人'
      },
      {
        name: '负面司法信息'
      },
      {
        name: '风险预警信息'
      },
      {
        name: '征信报告解读'
      }
    ])
    const indexList = ref([
      '基本信息',
      '最终受益人',
      '负面司法信息',
      '风险预警信息',
      '征信报告解读'
    ])

    useEventListener(
      'scroll',
      (e) => {
        const rect = useRect(scrolltabs)
        if (rect.y < 0) {
          fixed.value = true
        } else {
          fixed.value = false
          return
        }
      },
      { target: scrollParent }
    )

    // 滚动触发tab-item切换
    const scrollToTab = (tabName: any) => {
      const index = indexList.value.findIndex((name) => name === tabName)
      if (index >= 0) {
        tabIndex.value = index
      }
    }

    // 选中tab-item触发
    const changeTab = (index: number) => {
      indexBarRef.value?.scrollTo(indexList.value[index])
      if (index === 0) {
        // 解决tabindex为0时,虽然跳转了但是激活标签索引为1
        setTimeout(() => {
          tabIndex.value = index
        }, 10)
      }
    }
    // 展开栏回调
    const changeActive = (active: string[]) => {
      if (active && active.length <= 0) {
        tabIndex.value = -1
        fixed.value = false
      }
    }

    return {
      tabIndex,
      indexList,
      scrolltabs,
      indexBarRef,
      fixed,
      activeNames,
      tabsData,
      changeActive,
      changeTab,
      scrollToTab
    }
  }
})
