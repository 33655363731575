import request from '@/utils/request'


interface verifyCode {
  mobile: string,
  verifyCode: string,
  verifyId: string
}

interface verifyCodeTemp {
  appId: string,
  mobile: string,
  verifyCode: string,
  verifyId: string,
  tempToken: any
}
interface wx {
  appId: string,
  code: string,
  state?: string
}
interface mobile {
  mobile: string,
  tempToken?: any
}

interface mobileTemp {
  mobile: string,
  tempToken: any,
  appId: string,
}

interface wechatClient {
  clientId: string
}

/**
 * 验证码登录
 * @param params
 * @returns
 */
export function mobileLogin(params: verifyCode) {
  return request({
    url: '/creditStanding/login/mobileLogin',
    method: 'POST',
    data: { ...params }
  })
}
/**
 * wx登录
 * @param params
 * @returns
 */
export function wechatAuth(params: wx) {
  return request({
    url: '/creditStanding/login/wechatAuth',
    method: 'POST',
    data: { ...params }
  })
}
/**
 * 发送验证码
 * @param params
 * @returns
 */
export function mobileLoginCode(params: mobile) {
  return request({
    url: '/creditStanding/login/mobileLoginCode',
    method: 'POST',
    data: { ...params }
  })
}
/**
 * 发送验证码临时
 * @param params
 * @returns
 */
export function mobileLoginCodeTemp(params: mobileTemp) {
  return request({
    headers: { tempToken: params.tempToken, appId: params.appId },
    url: '/creditStanding/login/mobileLoginCode',
    method: 'POST',
    data: { mobile: params.mobile }
  })
}

/**
 * 验证码登录临时
 * @param params
 * @returns
 */
export function mobileLoginTemp(params: verifyCodeTemp) {
  return request({
    headers: { tempToken: params.tempToken, appId: params.appId },
    url: '/creditStanding/login/mobileLogin',
    method: 'POST',
    data: { mobile: params.mobile, verifyCode: params.verifyCode, verifyId: params.verifyId, }
  })
}

/**
 * 验证码登录临时
 * @param params
 * @returns
 */
export function wechatClient(params: wechatClient) {
  return request({
    url: '/creditStanding/login/wechatClient',
    method: 'POST',
    data: { ...params }
  })
}
