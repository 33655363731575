/**
 * 金融金额函数
 * @param Num 
 */
export function convertAmount(num: number | undefined | null | string) {
  if (num === undefined || num === null || num === '') {
    return ''
  }
  let integerStr = '0'
  let decimalStr = '00'
  integerStr = num.toString().split('.')[0]
  decimalStr = num.toString().split('.')[1] ? num.toString().split('.')[1] : '00'
  if (integerStr.length > 3) {
    let tmpInt = ''
    for (let i = 0; i < integerStr.length; i++) {
      if ((i) % 3 === 0 && i != 0) {
        tmpInt += ','
      }
      tmpInt += integerStr[integerStr.length - i - 1]
    }
    integerStr = tmpInt.split('').reverse().join('')
  }
  if (decimalStr.length < 2) {
    decimalStr = decimalStr + '0'
  }

  return `${integerStr}.${decimalStr}`
}