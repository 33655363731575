import router from './router'
import { getToken } from '@/utils/auth'

const whiteList = [
  '/login',
  '/redirect',
  '/404',
  '/loginForm',
  '/varifyCode'
]

router.beforeEach(async (to, from, next) => {
  const clientId: any = to.query.clientId
  if (clientId) {
    sessionStorage.setItem('clientId', clientId)
  }
  const title: any = to.meta.title || '链信'
  // 设置浏览器标题
  document.title = title
  const hasToken = getToken()
  if (hasToken) {
    next()
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      // 白名单
      next()
    } else {
      next(`/login`)
    }
  }
})
