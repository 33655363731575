import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "company_basic_info" }
const _hoisted_2 = {
  key: 0,
  class: "no_data"
}
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Cell = _resolveComponent("Cell")!
  const _component_van_cell_group = _resolveComponent("van-cell-group")!
  const _component_CustomerCpllapseVue = _resolveComponent("CustomerCpllapseVue")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_CustomerCpllapseVue, {
      active: _ctx.activeName,
      name: "1",
      title: "基本信息",
      icon: "/img/icons/basicInfo.png",
      iconStyle: "width:16.93px;height:12.78px;"
    }, {
      content: _withCtx(() => [
        (_ctx.data === '{}')
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, "无"))
          : (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_van_cell_group, { class: "cell_group" }, {
                default: _withCtx(() => [
                  _createVNode(_component_Cell, {
                    title: "企业名称",
                    value: _ctx.data.name,
                    labelWidth: 89.5
                  }, null, 8, ["value", "labelWidth"]),
                  _createVNode(_component_Cell, {
                    title: "法定代表人",
                    value: _ctx.data.legalPersonName,
                    "label-width": 89.5,
                    "value-color": "#5684FF"
                  }, null, 8, ["value", "label-width"]),
                  _createVNode(_component_Cell, {
                    title: "成立时间",
                    value: _ctx.data.establishTime,
                    labelWidth: 89.5
                  }, null, 8, ["value", "labelWidth"]),
                  _createVNode(_component_Cell, {
                    title: "注册资本",
                    value: _ctx.data.regCapital,
                    labelWidth: 89.5
                  }, null, 8, ["value", "labelWidth"]),
                  _createVNode(_component_Cell, {
                    title: "实缴资本",
                    value: _ctx.data.actualCapital,
                    labelWidth: 89.5
                  }, null, 8, ["value", "labelWidth"]),
                  _createVNode(_component_Cell, {
                    title: "企业类型",
                    value: _ctx.data.companyOrgType,
                    labelWidth: 89.5
                  }, null, 8, ["value", "labelWidth"]),
                  _createVNode(_component_Cell, {
                    title: "经营状态",
                    value: _ctx.data.regStatus,
                    labelWidth: 89.5
                  }, null, 8, ["value", "labelWidth"]),
                  _createVNode(_component_Cell, {
                    title: "行业分类",
                    value: _ctx.data.industry,
                    labelWidth: 89.5
                  }, null, 8, ["value", "labelWidth"])
                ]),
                _: 1
              }),
              _createVNode(_component_van_cell_group, { class: "cell_group" }, {
                default: _withCtx(() => [
                  _createVNode(_component_Cell, {
                    title: "统一社会信用代码",
                    value: _ctx.data.creditCode,
                    labelWidth: 127
                  }, null, 8, ["value"]),
                  _createVNode(_component_Cell, {
                    title: "纳税人识别号",
                    value: _ctx.data.taxNumber,
                    "label-width": 127
                  }, null, 8, ["value"])
                ]),
                _: 1
              }),
              _createVNode(_component_van_cell_group, { class: "cell_group" }, {
                default: _withCtx(() => [
                  _createVNode(_component_Cell, {
                    title: "登记机关",
                    value: _ctx.data.regInstitute,
                    labelWidth: 89.5
                  }, null, 8, ["value", "labelWidth"]),
                  _createVNode(_component_Cell, {
                    title: "注册地址",
                    value: _ctx.data.regLocation,
                    "label-width": 89.5
                  }, null, 8, ["value", "label-width"]),
                  _createVNode(_component_Cell, {
                    title: "经营范围",
                    value: _ctx.data.businessScope,
                    "label-width": 89.5
                  }, null, 8, ["value", "label-width"])
                ]),
                _: 1
              }),
              (_ctx.data.revokeDate || _ctx.data.cancelDate)
                ? (_openBlock(), _createBlock(_component_van_cell_group, {
                    key: 0,
                    class: "cell_group"
                  }, {
                    default: _withCtx(() => [
                      (_ctx.data.revokeDate)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                            _createVNode(_component_Cell, {
                              title: "吊销日期",
                              value: _ctx.data.revokeDate,
                              labelWidth: 89.5
                            }, null, 8, ["value", "labelWidth"]),
                            _createVNode(_component_Cell, {
                              title: "吊销原因",
                              value: _ctx.data.revokeReason,
                              "label-width": 89.5
                            }, null, 8, ["value", "label-width"])
                          ], 64))
                        : _createCommentVNode("", true),
                      (_ctx.data.cancelDate)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                            _createVNode(_component_Cell, {
                              title: "注销日期",
                              value: _ctx.data.cancelDate,
                              "label-width": 89.5
                            }, null, 8, ["value", "label-width"]),
                            _createVNode(_component_Cell, {
                              title: "注销原因",
                              value: _ctx.data.cancelReason,
                              "label-width": 89.5
                            }, null, 8, ["value", "label-width"])
                          ], 64))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]))
      ]),
      _: 1
    }, 8, ["active"])
  ]))
}