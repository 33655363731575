
import { ref, defineComponent } from 'vue'
import { Image as VanImage } from 'vant'
import VChart, { THEME_KEY } from 'vue-echarts'
import { use } from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { PieChart } from 'echarts/charts'
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  PolarComponent,
  GridComponent
} from 'echarts/components'

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  PolarComponent,
  GridComponent
])

export default defineComponent({
  name: 'ShareCard',
  components: {
    [VanImage.name]: VanImage,
    VChart
  },
  provide: {
    [THEME_KEY]: 'white'
  },
  props: {
    itemData: {
      type: Object as () => {
        logo: string
        name: string
        percent: number
      },
      required: true
    }
  },
  setup(props) {
    const piePption = ref({
      // backgroundColor: '#ccc',
      grid: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: '#F5F5F5'
      },
      title: {
        text: props.itemData.percent ? props.itemData.percent.toFixed(2) + '%' : '-',
        textStyle: {
          color: '#5684FF',
          fontSize: 13,
          fontWeight: '400'
        },
        subtext: '持股比例',
        subtextStyle: {
          color: '#8F94A8',
          fontSize: 13,
          fontWeight: '100'
        },
        itemGap: -32,
        left: 'center',
        top: '45'
      },
      angleAxis: {
        max: 100,
        clockwise: false,
        axisLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          show: false
        },
        splitLine: {
          show: false
        }
      },
      radiusAxis: {
        type: 'category',
        axisLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          show: false
        },
        splitLine: {
          show: false
        }
      },
      polar: {
        center: ['50%', '50%'],
        radius: '180%'
      },
      series: [
        {
          type: 'bar',
          data: [
            {
              name: '持股比例',
              value: props.itemData.percent ? props.itemData.percent : 0,
              itemStyle: {
                color: '#5684FF'
              }
            }
          ],
          coordinateSystem: 'polar',
          roundCap: true,
          barWidth: 8,
          barGap: '-100%',
          z: 2
        },
        {
          type: 'bar',
          data: [
            {
              value: 100,
              itemStyle: {
                color: '#e8e8e8',
                shadowColor: 'rgba(0, 0, 0, 0.01)',
                shadowBlur: 1,
                shadowOffsetY: 2
              }
            }
          ],
          coordinateSystem: 'polar',
          roundCap: true,
          barWidth: 8,
          barGap: '-100%',
          z: 1
        }
      ]
    })
    return {
      piePption
    }
  }
})
