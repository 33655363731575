
import { defineComponent, ref, watch } from 'vue'
import { Icon, Image as VanImage, Dialog } from 'vant'

export default defineComponent({
  name: 'NotOpenComponent',
  props: {
    active: Boolean
  },
  components: {
    [VanImage.name]: VanImage,
    [Icon.name]: Icon,
    [Dialog.Component.name]: Dialog.Component
  },
  setup(props, { emit }) {
    const show = ref(false)
    watch(
      () => props.active,
      () => {
        show.value = props.active
      }
    )

    const handleClick = () => {
      emit('cancelDialog')
    }

    return { show, close, handleClick }
  },
  methods: {}
})
