
import { defineComponent, reactive, ref } from 'vue'
import { Button, Form, Field, CellGroup, Image as VanImage, Icon, Loading } from 'vant'
import { useRouter } from 'vue-router'
import router from '../../router/index'
import {
  getEnterpriseListByKeyword,
  prepareEnterprisePortrait,
  browseHistories,
  clearBrowseHistories
} from '@/service/enterpriseService'
import WaveLoading from '@/components/waveLoading/index.vue'

interface item {
  creditCode: string
  img: string
  name: string
  legalPersonName: string
  regStatus: string
  regCapital: string
  establishDate: string
  matchType: string
}

interface history {
  custId: string
  companyLogo: string
  companyName: string
  browseTime: string
}

export default defineComponent({
  name: 'SearchCompany',
  components: {
    [Button.name]: Button,
    [Form.name]: Form,
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
    [VanImage.name]: VanImage,
    [Icon.name]: Icon,
    [Loading.name]: Loading,
    WaveLoading
  },
  setup() {
    const isSearch = ref(false)
    const loading = ref(false)
    const isComposition = ref(false)
    const value = ref('')
    const list = reactive<item[]>([])
    const latelyList = reactive([])
    const historyList = reactive([])
    const timeout = ref(0)
    const inputTime = ref(0)
    const router = useRouter()
    const cancel = () => {
      router.push('/home')
    }

    return {
      value,
      list,
      timeout,
      inputTime,
      cancel,
      isSearch,
      latelyList,
      historyList,
      loading,
      isComposition
    }
  },
  created() {
    // 获取搜索历史
    this.latelyList = JSON.parse(localStorage.getItem('lately') || '[]')
    this.getHistory()
  },
  activated() {
    // 获取搜索历史
    this.latelyList = JSON.parse(localStorage.getItem('lately') || '[]')
    this.getHistory()
  },
  methods: {
    async getHistory() {
      try {
        const params = {
          current: 1,
          size: 10
        }
        const res = await browseHistories(params)
        this.historyList = res.data.records
        this.$forceUpdate()
      } catch (err) {
        console.log(err)
      }
    },
    handleCompsition(e: any) {
      // 拼音输入事件
      if (e.type === 'compositionend') {
        // 判断输入状态是否 是 拼音输入结束状态
        this.isComposition = false
        this.searchInput(e)
      } else {
        this.isComposition = true
      }
    },
    // 搜索框变化
    searchInput(e: any) {
      this.inputTime = e.timeStamp
      if (this.isComposition) {
        return
      }
      if (e.inputType === 'deleteContentBackward') {
        this.timeout = setTimeout(() => {
          if (this.inputTime - e.timeStamp === 0) {
            if (this.value.length === 0) {
              this.isSearch = false
            }
            if (this.value.length > 1) {
              this.search()
            }
          }
        }, 300)
        return
      }
      if (!this.timeout) {
        clearTimeout(this.timeout)
      }
      this.timeout = setTimeout(() => {
        if (this.value.length === 0) {
          this.isSearch = false
        }
        if (this.value.length > 1) {
          this.search()
        }
      }, 500)
    },
    onCompositionEnd(e: any) {
      e.isComposing = false
      this.search()
    },
    onCompositionStart(e: any) {
      e.isComposing = true
    },
    // 管控搜索
    search() {
      if (this.value.length > 1) {
        this.getEnterprise()
      }
    },
    // 搜索接口
    async getEnterprise() {
      try {
        this.isSearch = true
        this.loading = true
        const params = {
          current: 1,
          size: 10,
          keyword: this.value
        }
        const res = await getEnterpriseListByKeyword(params)
        this.list = res.data.records
      } catch (err) {
        this.list = []
        this.isSearch = false
      } finally {
        this.loading = false
      }
    },
    // 点击公司进入下一步
    goNext(item: item) {
      this.prepareNext(item)
      let arr = JSON.parse(localStorage.getItem('lately') || '[]')
      if (!arr || arr.length === 0) {
        arr = [this.value]
      } else {
        if (!arr.includes(this.value)) {
          arr.unshift(this.value)
        }
      }
      localStorage.setItem('lately', JSON.stringify(arr))
      sessionStorage.setItem('enterprise', item.name)
      router.push({
        path: '/completInfo'
      })
    },
    async prepareNext(item: item) {
      try {
        const params = {
          keyword: item.name
        }
        await prepareEnterprisePortrait(params)
      } catch (error) {
        console.log(error)
      }
    },
    // 点击搜索记录
    latelyClick(name: string) {
      this.value = name
      this.search()
    },
    // 清除搜索框
    clearInput() {
      this.isSearch = false
    },
    // 清除搜索记录
    delLately() {
      localStorage.removeItem('lately')
      this.latelyList = JSON.parse(localStorage.getItem('lately') || '[]')
      this.$forceUpdate()
    },
    // 清除历史记录
    async delHistory() {
      try {
        await clearBrowseHistories()
        this.historyList = []
        this.$forceUpdate()
      } catch (err) {
        console.log(err)
      }
    },
    // 点击历史记录
    historyClick(child: history) {
      sessionStorage.setItem('enterprise', child.companyName)
      router.push({
        path: '/completInfo'
      })
    }
  }
})
