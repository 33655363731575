import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a09cc29c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ultimate_beneficiary" }
const _hoisted_2 = {
  key: 0,
  class: "no_data"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UltimateCard = _resolveComponent("UltimateCard")!
  const _component_CustomerCpllapseVue = _resolveComponent("CustomerCpllapseVue")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_CustomerCpllapseVue, {
      active: _ctx.activeName,
      name: "3",
      title: "最终受益人",
      icon: "/img/icons/ultimateBeneficiary.png"
    }, {
      content: _withCtx(() => [
        (_ctx.data.length === 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, "无"))
          : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.data, (item, index) => {
              return (_openBlock(), _createBlock(_component_UltimateCard, {
                key: item.percent + index,
                itemData: item
              }, null, 8, ["itemData"]))
            }), 128))
      ]),
      _: 1
    }, 8, ["active"])
  ]))
}