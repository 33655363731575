
import { ref, defineComponent, PropType, onMounted } from 'vue'
import { Collapse, Tab, Tabs, IndexBar, IndexAnchor } from 'vant'
import { useRect, useScrollParent, useEventListener } from '@vant/use'
import type { IndexBarInstance } from 'vant'
import CustomerBasicInfoVue from './modules/CustomerBasicInfo.vue'
import JudicialDishonestInformation from './modules/JudicialDishonestInformation.vue'
import DefaultRecord from './modules/DefaultRecord.vue'
import EmploymentIncome from './modules/EmploymentIncome.vue'
import InternetBehavior from './modules/InternetBehavior.vue'
import PersonalCreditRating from './modules/PersonalCreditRating.vue'
import LendingFrequency from './modules/LendingFrequency.vue'

import { ICustomerInfo } from '@/interfaces/customerReportType'
import CustomerCpllapseVue from '@/components/customerCollapse/CustomerCpllapse.vue'
import InterpretationOfCredit from './modules/interpretationOfCredit.vue'
import ScrollTabs from '@/components/scrollTabs/index.vue'

// 声明props
interface IProps {
  customerInfo: ICustomerInfo
}

export default defineComponent({
  name: 'CustomerPortrait',
  components: {
    [Collapse.name]: Collapse,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [IndexBar.name]: IndexBar,
    [IndexAnchor.name]: IndexAnchor,
    CustomerBasicInfoVue,
    JudicialDishonestInformation,
    DefaultRecord,
    EmploymentIncome,
    InternetBehavior,
    PersonalCreditRating,
    LendingFrequency,
    CustomerCpllapseVue,
    InterpretationOfCredit,
    ScrollTabs
  },
  props: {
    customerInfo: {
      type: Object as PropType<ICustomerInfo>,
      required: true
    }
  },
  data() {
    return {
      active: []
    }
  },
  setup(props) {
    const activeNegativeJudicial = ref<string[]>([])
    const activeNames = ref<string[]>([])
    const tabIndex = ref(-1)
    const fixed = ref(false)
    const scrolltabs = ref()
    const scrollParent = useScrollParent(scrolltabs)

    const indexBarRef = ref<IndexBarInstance>()
    const customerBasicInfo = {
      name: props.customerInfo.name,
      mobile: props.customerInfo.mobile,
      idCard: props.customerInfo.idCard,
      businessRelationship: props.customerInfo.businessRelationship,
      telPeriodPro: props.customerInfo.telPeriodPro,
      telNetworkStatus: props.customerInfo.telNetworkStatus,
      isOneselfTel: props.customerInfo.isOneselfTel
    }
    const nearlyLoanData = {
      nearlySevenDaysNum: props.customerInfo.nearlySevenDaysNum,
      nearlyThreeMonthsNum: props.customerInfo.nearlyThreeMonthsNum,
      nearlySixMonthsNum: props.customerInfo.nearlySixMonthsNum,
      nearlyTwelveMonthsNum: props.customerInfo.nearlyTwelveMonthsNum,
      nearlySevenDaysOrgNum: props.customerInfo.nearlySevenDaysOrgNum,
      nearlyThreeMonthsOrgNum: props.customerInfo.nearlyThreeMonthsOrgNum,
      nearlySixMonthsOrgNum: props.customerInfo.nearlySixMonthsOrgNum,
      nearlyTwelveMonthsOrgNum: props.customerInfo.nearlyTwelveMonthsOrgNum
    }

    const tabsData = ref([
      {
        name: '基本信息'
      },
      {
        name: '互联网行为'
      },
      {
        name: '司法失信信息'
      },
      {
        name: '消费能力'
      },
      {
        name: '金融信用'
      },
      {
        name: '征信报告解读'
      }
    ])
    const indexList = ref([
      '基本信息',
      '互联网行为',
      '司法失信信息',
      '消费能力',
      '金融信用',
      '征信报告解读'
    ])
    useEventListener(
      'scroll',
      (e) => {
        const rect = useRect(scrolltabs)
        if (rect.y < 0) {
          fixed.value = true
        } else {
          fixed.value = false
          return
        }
      },
      { target: scrollParent }
    )

    // 滚动触发tab-item切换
    const scrollToTab = (tabName: any) => {
      const index = indexList.value.findIndex((name) => name === tabName)
      if (index >= 0) {
        tabIndex.value = index
      }
    }

    // 选中tab-item触发
    const changeTab = (index: number) => {
      indexBarRef.value?.scrollTo(indexList.value[index])
      if (index === 0) {
        // 解决tabindex为0时,虽然跳转了但是激活标签索引为1
        setTimeout(() => {
          tabIndex.value = index
        }, 10)
      }
    }
    // 展开栏回调
    const changeActive = (active: string[]) => {
      if (active && active.length <= 0) {
        tabIndex.value = -1
        fixed.value = false
      }
    }

    return {
      activeNames,
      activeNegativeJudicial,
      customerBasicInfo,
      nearlyLoanData,
      tabsData,
      scrolltabs,
      tabIndex,
      indexBarRef,
      fixed,
      scrollParent,
      indexList,
      changeActive,
      changeTab,
      scrollToTab
    }
  }
})
