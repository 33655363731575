<template>
  <div class="padding font_12">
    <div class="d_flex_row align_items_stretch mgb_20">
      <div class="flex_1">
        <div class="">最早一张销项开票距今月份数</div>
        <div class="text_orange">200</div>
      </div>
      <div class="divider_vertical mgr_8"></div>
      <div class="flex_1">
        <div class="">累计客户数量</div>
        <div class="text_orange">1000</div>
      </div>
    </div>
    <div class="d_flex_row mgb_20">
      <div class="flex_1">
        <div class="">客户平均交易月份数(近12个月)</div>
        <div class="text_orange">10</div>
      </div>
      <div class="divider_vertical mgr_8"></div>
      <div class="flex_1">
        <div class="">最大客户销售占比</div>
        <div class="text_orange">60%</div>
      </div>
    </div>
    <div class="d_flex_row align_items_stretch mgb_20">
      <div class="flex_1">
        <div class="">距今未开票天数</div>
        <div class="text_orange">20</div>
      </div>
      <div class="divider_vertical mgr_8"></div>
      <div class="flex_1">
        <div class="">冲红作废比例(近12个月)</div>
        <div class="text_orange">20%</div>
      </div>
    </div>
    <div class="d_flex_row align_items_stretch">
      <div class="flex_1">
        <div class="">最高月度开票金额占开票总金额占比(近12个月)</div>
        <div class="text_orange">50%</div>
      </div>
      <div class="divider_vertical mgr_8 hidden"></div>
      <div class="flex_1"></div>
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
.font_13 {
  font-size: 13px;
}
.font_12 {
  font-size: 12px;
}
.divider {
  width: 100%;
  height: 1px;
  background: #ededed;
  margin-bottom: 17px;
}

.divider_vertical {
  height: auto;
  width: 1px;
  background: #ededed;
}

.align_items_stretch {
  align-items: stretch;
}
.text_black {
  color: #27314a;
}
.text_orange {
  color: #e76964;
}
.text_gray {
  color: #8f94a8;
}
.d_flex {
  display: flex;
}
.d_flex_row {
  display: flex;
  flex-direction: row;
}
.d_flex_column {
  display: flex;
  flex-direction: column;
}
.flex_1 {
  flex: 1;
}
.padding {
  padding: 20px;
}
.block_wrap {
  padding: 18px 16px;
  background: #fbfbfb;
  border-right: 10px;
  width: 179px;
}
.mgr_1 {
  margin-right: 5px;
}
.mgr_8 {
  margin-right: 8px;
}
.mgb_17 {
  margin-bottom: 17px;
}
.mgb_20 {
  margin-bottom: 20px;
}
.hidden {
  visibility: hidden;
}
</style>
