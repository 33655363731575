
import { defineComponent, reactive } from 'vue'

export default defineComponent({
  name: 'WaveLoading',
  props: {
    loading: {},
    style: {}
  },
  setup() {
    return {}
  }
})
