import request from '@/utils/request'

interface IPersonalCheck {
  idCard: string
  mobile: string
  name: string
  verifyCode: string
  verifyId: string
}

interface ISmsCode {
  mobile: string
}

interface IPersonalPortrait {
  businessRelationship: string
  // idCard: string
  // mobile: string
  // name: string
  verificationId: string
}

/**
 * 个人三要素校验
 * @param params
 * @returns
 */
export function personalCheck(params: IPersonalCheck) {
  return request({
    url: '/creditStanding/personal/personalCheck',
    method: 'POST',
    data: { ...params }
  })
}
/**
 * 预加载个人画像信息
 * @param params
 * @returns
 */
export function preparePersonalPortrait(params: IPersonalPortrait) {
  return request({
    url: '/creditStanding/personal/preparePersonalPortrait',
    method: 'POST',
    data: { ...params }
  })
}
/**
 * 发送校验验证码
 * @param params
 * @returns
 */
export function sendSmsCode(params: ISmsCode) {
  return request({
    url: '/creditStanding/personal/sendSmsCode',
    method: 'POST',
    data: { ...params }
  })
}
/**
 * 获取个人画像信息
 * @param params
 * @returns
 */
export function getPersonalPortrait(params: IPersonalPortrait) {
  return request({
    url: '/creditStanding/personal/getPersonalPortrait',
    method: 'POST',
    data: { ...params }
  })
}
