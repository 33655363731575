import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './styles/index.scss'
import './permission'
import eruda from 'eruda';
import { ConfigProvider } from 'vant';
import { convertAmount } from './filters/amount'

if (process.env.NODE_ENV === 'development') {
  const el = document.createElement('div')
  document.body.appendChild(el)

  eruda.init({
    container: el,
    tool: ['console', 'elements', 'network', 'resources']
  })
}
const app = createApp(App);
app.config.globalProperties.$filters = {
  convertAmount
}

app.use(ConfigProvider)
  .use(store)
  .use(router).mount('#app')
