
import { ref } from 'vue'
import { defineComponent } from '@vue/runtime-core'
import { Collapse, CollapseItem } from 'vant'
import PleaseExpect from '@/components/pleaseExpect/PleaseExpect.vue'
import { Image as VanImage } from 'vant'

export default defineComponent({
  name: 'RatingInterpretation',
  components: {
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [VanImage.name]: VanImage,
    PleaseExpect
  },
  setup() {
    const active = ref('1')
    return {
      active
    }
  }
})
