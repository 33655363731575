
import { defineComponent } from '@vue/runtime-core'
import NegativeInfoCardItem from './module/card.vue'
import { Image as VanImage } from 'vant'

export default defineComponent({
  name: 'NegativeInformation',
  components: {
    NegativeInfoCardItem,
    [VanImage.name]: VanImage
  },
  props: {
    companyNegativeInfoContent: {
      type: Array as () => string[],
      default: function () {
        return []
      }
    },
    customerNegativeInfoContent: {
      type: Array as () => string[],
      default: function () {
        return []
      }
    }
  }
})
