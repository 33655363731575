export const moneyShow = function (val: number): string {
  if (val === 0 || val === null) {
    return '0.00'
  }
  if (val === null) {
    return ''
  }

  return commafy(doubleDigit(val))
}

function doubleDigit(value: number) {
  // 保留两位小数
  if (isNaN(value) || value === null) {
    return ''
  }
  const val = Math.round(parseFloat(value.toString()) * 100) / 100
  const num = val.toString().split('.')
  if (num.length === 1) {
    return val.toString() + '.00'
  } else if (num[1].length === 1) {
    return val.toString() + '0'
  } else if (num[1].length === 2) {
    return val.toString()
  } else {
    return num[0] + '.' + num[1].substring(0, 2)
  }
}

function commafy(num: string) {
  if ((num + '').trim() === '') {
    return ''
  }
  num = num + ''
  if (/^.*\..*$/.test(num)) {
    const pointIndex = num.lastIndexOf('.')
    let intPart = num.substring(0, pointIndex)
    const pointPart = num.substring(pointIndex + 1, num.length)
    intPart = intPart + ''
    const re = /(-?\d+)(\d{3})/
    while (re.test(intPart)) {
      intPart = intPart.replace(re, '$1,$2')
    }
    num = intPart + '.' + pointPart
  } else {
    num = num + ''
    const re = /(-?\d+)(\d{3})/
    while (re.test(num)) {
      num = num.replace(re, '$1,$2')
    }
  }
  return num
}
