
import { ref } from 'vue'
import { defineComponent } from '@vue/runtime-core'
import PleaseExpect from '@/components/pleaseExpect/PleaseExpect.vue'

export default defineComponent({
  name: 'TopCard',
  props: {
    title: {
      type: String,
      required: true,
      default: ''
    }
  },
  components: {
    PleaseExpect
  },
  setup(props) {
    const itemTitle = ref(props.title)
    return {
      itemTitle
    }
  }
})
