import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-529b62de"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "please_expect_page" }
const _hoisted_2 = { class: "context" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      src: "/img/icons/pleaseExpect.png",
      class: "icon",
      style: _normalizeStyle(_ctx.style)
    }, null, 4),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.content), 1)
  ]))
}