
import { defineComponent, ref } from 'vue'
import { Button, Form, Field, CellGroup, Image as VanImage, Icon } from 'vant'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'HomePage',
  components: {
    [Button.name]: Button,
    [Form.name]: Form,
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
    [VanImage.name]: VanImage,
    [Icon.name]: Icon
  },
  setup() {
    const router = useRouter()

    const toNext = () => {
      router.push('/search')
    }

    return {
      toNext
    }
  },
  methods: {},
  beforeRouteLeave(to, from, next) {
    if (to.path === '/redirect' || to.path === '/varifyCode') {
      next('/login')
    } else {
      next()
    }
  }
})
