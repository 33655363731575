
import { defineComponent, reactive, ref, watch } from 'vue'
import CustomerCpllapseItem from '@/components/customerCollapse/CustomerCpllapse.vue'
import { Collapse, CollapseItem, IndexBar, IndexAnchor } from 'vant'
import BusinessRisk from './modules/businessRisk/index.vue'
import CoreFinanceIndicators from './modules/coreFinanceIndicators/index.vue'
import IncomeTrend from './modules/incomeTrend/index.vue'
import InvoicingSituation from './modules/invoicingSituation/index.vue'
import InvoiceTaxRate from './modules/invoiceTaxRate/index.vue'
import MainCommodity from './modules/mainCommodity/index.vue'
import FrequencyStatistics from './modules/frequencyStatistics/index.vue'
import AmountStatistics from './modules/amountStatistics/index.vue'
import SupplierFrequencyStatistics from './modules/frequencyStatistics/index.vue'
import SupplierAmountStatistics from './modules/amountStatistics/index.vue'
import DownstreamAnalysis from './modules/downstreamAnalysis/index.vue'
import PurchasingGoods from './modules/purchasingGoods/index.vue'
import ScrollTabs from '@/components/scrollTabs/index.vue'
import { useRect, useScrollParent, useEventListener } from '@vant/use'
import type { IndexBarInstance } from 'vant'

export default defineComponent({
  name: 'CompanyManagementPortrait',
  props: {},
  data() {
    return {
      activeNames: [],
      tabsData: [
        {
          name: '经营风险指标'
        },
        {
          name: '核心财务指标'
        },
        {
          name: '经营收入趋势'
        },
        {
          name: '销项开票情况'
        },
        {
          name: '销项发票税率占比'
        },
        {
          name: '主营商品'
        },
        {
          name: '交易频次十大客户'
        },
        {
          name: '交易金额十大客户'
        },
        {
          name: '下游客户情况分析'
        },
        {
          name: '交易频次十大供应商'
        },
        {
          name: '交易金额十大供应商'
        },
        {
          name: '采购商品情况'
        }
      ]
    }
  },
  methods: {},
  setup() {
    const scrolltabs = ref()
    const tabIndex = ref(-1)
    const fixed = ref(false)
    const scrollParent = useScrollParent(scrolltabs)
    const indexBarRef = ref<IndexBarInstance>()
    const indexList = ref([
      '经营风险指标',
      '核心财务指标',
      '经营收入趋势',
      '销项开票情况',
      '销项发票税率占比',
      '主营商品',
      '交易频次十大客户',
      '交易金额十大客户',
      '下游客户情况分析',
      '交易频次十大供应商',
      '交易金额十大供应商',
      '采购商品情况'
    ])

    useEventListener(
      'scroll',
      (e) => {
        const rect = useRect(scrolltabs)
        if (rect.y < 0) {
          fixed.value = true
        } else {
          fixed.value = false
          return
        }
      },
      { target: scrollParent }
    )

    // 滚动触发tab-item切换
    const scrollToTab = (tabName: any) => {
      const index = indexList.value.findIndex((name) => name === tabName)
      if (index >= 0) {
        tabIndex.value = index
      }
    }

    // 选中tab-item触发
    const changeTab = (index: number) => {
      indexBarRef.value?.scrollTo(indexList.value[index])
      if (index === 0) {
        // 解决tabindex为0时,虽然跳转了但是激活标签索引为1
        setTimeout(() => {
          tabIndex.value = index
        }, 10)
      }
    }
    // 展开栏回调
    const changeActive = (active: string[]) => {
      if (active && active.length <= 0) {
        tabIndex.value = -1
        fixed.value = false
      }
    }
    return {
      tabIndex,
      indexList,
      scrolltabs,
      indexBarRef,
      fixed,
      scrollToTab,
      changeTab,
      changeActive
    }
  },
  components: {
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [IndexBar.name]: IndexBar,
    [IndexAnchor.name]: IndexAnchor,
    CustomerCpllapseItem: CustomerCpllapseItem,
    BusinessRisk,
    CoreFinanceIndicators,
    IncomeTrend,
    InvoicingSituation,
    InvoiceTaxRate,
    MainCommodity,
    FrequencyStatistics,
    AmountStatistics,
    DownstreamAnalysis,
    SupplierAmountStatistics,
    SupplierFrequencyStatistics,
    PurchasingGoods,
    ScrollTabs
  }
})
