import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-66729b9e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "negative_info_item" }
const _hoisted_2 = { class: "info_title" }
const _hoisted_3 = { class: "content" }
const _hoisted_4 = { class: "acount_num" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_image = _resolveComponent("van-image")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.title), 1),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.content, (item, index) => {
        return (_openBlock(), _createElementBlock("span", {
          key: `item_${index}`,
          class: "context_item theme_text_color"
        }, _toDisplayString(item), 1))
      }), 128)),
      _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.content.length), 1),
      _createVNode(_component_van_image, {
        src: "/img/icons/riskflash.png",
        class: "icon"
      })
    ])
  ]))
}