import request from '@/utils/request'
import { ICompany } from '@/interfaces/companyReportType'
import { number } from 'echarts/core'

interface EnterpriseList {
  keyword: string
}

interface history {
  current?: number,
  size?: number
}

/**
 * 模糊查询企业
 * @param params
 * @returns
 */
export function getEnterpriseListByKeyword(params: EnterpriseList) {
  return request({
    url: '/creditStanding/enterprise/getEnterpriseListByKeyword',
    method: 'POST',
    data: { ...params }
  })
}

/**
 * 预加载查询企业
 * @param params
 * @returns
 */
export function prepareEnterprisePortrait(params: EnterpriseList) {
  return request({
    url: '/creditStanding/enterprise/prepareEnterprisePortrait',
    method: 'POST',
    data: { ...params }
  })
}

/**
 * 获取企业画像信息
 * @param params
 * @returns
 */
export function getEnterprisePortrait(params: EnterpriseList) {
  return request<{ code: string; data: ICompany }>({
    url: '/creditStanding/enterprise/getEnterprisePortrait',
    method: 'POST',
    data: { ...params }
  })
}

/**
 * 获取浏览历史
 * @param params
 * @returns
 */
export function browseHistories(params: history) {
  return request({
    url: '/creditStanding/enterprise/browseHistories',
    method: 'GET',
    params: { ...params }
  })
}

/**
 * 清除浏览历史
 * @param params
 * @returns
 */
 export function clearBrowseHistories() {
  return request({
    url: '/creditStanding/enterprise/clearBrowseHistories',
    method: 'POST',
    data: {}
  })
}

