
import { defineComponent } from '@vue/runtime-core'
import { Image as VanImage } from 'vant'

export default defineComponent({
  name: 'PleseExpect',
  components: {
    [VanImage.name]: VanImage
  },
  props: {
    style: Object,
    content: String
  }
})
