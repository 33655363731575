
import { defineComponent } from '@vue/runtime-core'
import { Image as VanImage } from 'vant'

export default defineComponent({
  name: 'NegativeInfoCardItem',
  components: {
    [VanImage.name]: VanImage
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    content: {
      type: Array as () => string[],
      default: function () {
        return []
      }
    }
  },
  setup() {
    return {}
  }
})
