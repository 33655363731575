/*
 * @Author: aoqing
 * @Date: 2021-11-29 15:12:58
 * @Last Modified by: aoqing
 * @Last Modified time: 2022-01-18 18:27:21
 */
export const option = {
  // backgroundColor: '#ccc',
  grid: {
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: '#F5F5F5'
  },
  title: {
    text: '90.00%',
    textStyle: {
      color: '#5684FF',
      fontSize: 13,
      fontWeight: '400'
    },
    subtext: '持股比例',
    subtextStyle: {
      color: '#8F94A8',
      fontSize: 13,
      fontWeight: '100'
    },
    itemGap: -32,
    left: 'center',
    top: '45'
  },
  angleAxis: {
    max: 100,
    clockwise: false,
    axisLine: {
      show: false
    },
    axisTick: {
      show: false
    },
    axisLabel: {
      show: false
    },
    splitLine: {
      show: false
    }
  },
  radiusAxis: {
    type: 'category',
    axisLine: {
      show: false
    },
    axisTick: {
      show: false
    },
    axisLabel: {
      show: false
    },
    splitLine: {
      show: false
    }
  },
  polar: {
    center: ['50%', '50%'],
    radius: '180%'
  },
  series: [
    {
      type: 'bar',
      data: [
        {
          name: '持股比例',
          value: 65,
          itemStyle: {
            color: '#5684FF'
          }
        }
      ],
      coordinateSystem: 'polar',
      roundCap: true,
      barWidth: 8,
      barGap: '-100%',
      z: 2
    },
    {
      type: 'bar',
      data: [
        {
          value: 100,
          itemStyle: {
            color: 'rgba(121, 52, 243, 0.1)',
            shadowColor: 'rgba(0, 0, 0, 0.2)',
            shadowBlur: 5,
            shadowOffsetY: 2
          }
        }
      ],
      coordinateSystem: 'polar',
      roundCap: true,
      barWidth: 8,
      barGap: '-100%',
      z: 1
    }
  ]
}

// 商品销售次数占比
export const percentageOfSalesOption = {
  // tooltip: {
  //   trigger: 'item'
  // },
  title: {
    text: '商品销售次数占比(%)',
    left: 'center',
    top: '5%',
    textStyle: {
      color: '#27314A',
      fontSize: 14,
      fontWeight: 400,
    },
  },
  legend: {
    top: 'center',
    right: '10%',
    icon: "circle",
    itemHeight: 10,
    itemWidth: 10,
    width: '40%',
    formatter: (name: string) => {
      // const item = percentageOfAmountOption.series[0].data.find((i) => i.name === name)
      // let sum = 0;
      // percentageOfAmountOption.series[0].data.forEach(i => { sum += i.value })
      // const p = ((item!.value / sum) * 100).toFixed(0);
      const p = 25
      return `{per| ${p}%}\n {name| ${name}}`;
    },
    textStyle: {
      rich: {
        per: {
          color: '#27314A',
          fontSize: 18
        },
        name: {
          color: '#8F94A8',
          fontSize: 13
        }
      }
    }
  },
  series: [
    {
      name: 'Access From',
      type: 'pie',
      radius: ['30%', '45%'],
      center: ['25%', '50%'],
      avoidLabelOverlap: false,
      itemStyle: {
        borderColor: '#fff',
        borderWidth: 2
      },
      label: {
        show: false,
        position: 'center'
      },
      emphasis: {
        label: {
          show: false,
          fontSize: '40',
          fontWeight: 'bold'
        }
      },
      labelLine: {
        show: false
      },
      data: [
        { value: 1048, name: '商品A', selected: true, },
        { value: 735, name: '商品B' },
        { value: 580, name: '商品C' },
        { value: 484, name: '商品D' },
        { value: 400, name: '商品E' },
        { value: 300, name: '商品F' }
      ]
    }
  ]
};
// 商品销售次数占比
export const percentageOfAmountOption = {
  // tooltip: {
  //   trigger: 'item'
  // },
  title: {
    text: '商品销售金额占比(%)',
    left: 'center',
    top: '5%',
    textStyle: {
      color: '#27314A',
      fontSize: 14,
      fontWeight: 400,
    },
  },
  legend: {
    top: 'center',
    right: '10%',
    icon: "circle",
    itemHeight: 10,
    itemWidth: 10,
    width: '40%',
    formatter: (name: string) => {
      // const item = percentageOfAmountOption.series[0].data.find((i) => i.name === name)
      // let sum = 0;
      // percentageOfAmountOption.series[0].data.forEach(i => { sum += i.value })
      // const p = ((item!.value / sum) * 100).toFixed(0);
      const p = 25
      return `{per| ${p}%}\n {name| ${name}}`;
    },
    textStyle: {
      rich: {
        per: {
          color: '#27314A',
          fontSize: 18
        },
        name: {
          color: '#8F94A8',
          fontSize: 13
        }
      }
    }
  },
  series: [
    {
      name: 'Access From',
      type: 'pie',
      radius: ['30%', '45%'],
      center: ['25%', '50%'],
      avoidLabelOverlap: false,
      itemStyle: {
        borderColor: '#fff',
        borderWidth: 2
      },
      label: {
        show: false,
        position: 'center'
      },
      emphasis: {
        label: {
          show: false,
          fontSize: '40',
          fontWeight: 'bold'
        }
      },
      labelLine: {
        show: false
      },
      data: [
        { value: 348, name: '商品A' },
        { value: 835, name: '商品B' },
        { value: 180, name: '商品C' },
        { value: 684, name: '商品D' },
        { value: 400, name: '商品E' }
      ]
    }
  ]
};

// 