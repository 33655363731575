import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, normalizeClass as _normalizeClass, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_image = _resolveComponent("van-image")!
  const _component_van_cell = _resolveComponent("van-cell")!

  return (_openBlock(), _createBlock(_component_van_cell, { class: "customer_cell" }, {
    title: _withCtx(() => [
      _createElementVNode("div", {
        class: "cell_title",
        style: _normalizeStyle(`width: ${_ctx.labelItemWidth}px;`)
      }, _toDisplayString(_ctx.title), 5)
    ]),
    value: _withCtx(() => [
      _createElementVNode("div", {
        class: "cell_value",
        style: _normalizeStyle([`color:${_ctx.valueItemColor};`, _ctx.textStyle])
      }, _toDisplayString(_ctx.value), 5),
      _withDirectives(_createElementVNode("span", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.changOpen && _ctx.changOpen(...args))),
        class: "show_btn"
      }, [
        _createTextVNode(_toDisplayString(!_ctx.changeBtn ? '··· 展开' : '收起') + " ", 1),
        _withDirectives(_createElementVNode("div", {
          class: _normalizeClass(["icon left_icon", _ctx.changeBtn ? '' : 'left_icon_active'])
        }, [
          _createVNode(_component_van_image, {
            width: "7px",
            height: "5px",
            src: "/img/icons/row.png"
          })
        ], 2), [
          [_vShow, _ctx.showOpen]
        ])
      ], 512), [
        [_vShow, _ctx.showOpen]
      ])
    ]),
    _: 1
  }))
}