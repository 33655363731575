
import { ref, defineComponent } from 'vue'
export default defineComponent({
  name: 'App',
  setup() {
    // 需要缓存的组件name值
    const includeList = ref(['SearchCompany'])
    return {
      includeList
    }
  }
})
