
import { defineComponent, ref } from 'vue'
import { use } from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { PieChart, GaugeChart, BarChart } from 'echarts/charts'
import VChart, { THEME_KEY } from 'vue-echarts'
import { actionOption, incomeOption, ceditOption } from '@/config/gauge'
import { option } from '@/config/pie'
import { option as lineOption } from '@/config/line'

import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  PolarComponent,
  GridComponent
} from 'echarts/components'

// echart 按需加载
use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  PolarComponent,
  GridComponent,
  GaugeChart,
  BarChart
])

export default defineComponent({
  name: 'WelcomePage',
  components: {
    VChart
  },
  provide: {
    [THEME_KEY]: 'white'
  },
  setup() {
    // pie
    const piePption = ref(option)
    // gauge
    const gauge = ref(actionOption)
    const income = ref(incomeOption)
    const cedit = ref(ceditOption)
    const line = ref(lineOption)
    return { piePption, gauge, income, cedit, line }
  }
})
