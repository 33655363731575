import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-25c8c75e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "page redirect_page" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_wave_loading = _resolveComponent("wave-loading")!
  const _component_not_open = _resolveComponent("not-open")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_wave_loading, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_component_not_open, {
      active: _ctx.showDialog,
      onCancelDialog: _ctx.cancelDialog
    }, null, 8, ["active", "onCancelDialog"])
  ]))
}