
import { CellGroup } from 'vant'
import { defineComponent } from '@vue/runtime-core'
import CustomerCpllapseVue from '@/components/customerCollapse/CustomerCpllapse.vue'
import Cell from '@/components/customerCell/index.vue'
import { ICompanyInfo } from '@/interfaces/companyReportType'
import { moneyShow } from '@/utils/moneyTools'

export default defineComponent({
  name: 'CompanyBasicInfo',
  props: {
    activeName: Array as () => string[],
    data: {
      type: Object as () => ICompanyInfo,
      required: true,
      default: function () {
        return {
          actualCapital: '',
          businessScope: '',
          cancelDate: '',
          cancelReason: '',
          companyOrgType: '',
          creditCode: '',
          establishTime: '',
          industry: '',
          legalPersonName: '',
          name: '',
          regCapital: 0,
          regInstitute: '',
          regLocation: '',
          regStatus: '',
          revokeDate: '',
          revokeReason: '',
          taxNumber: ''
        }
      }
    }
  },
  components: {
    [CellGroup.name]: CellGroup,
    Cell,
    CustomerCpllapseVue
  },
  setup() {
    const moneyShowItem = moneyShow
    return {
      moneyShowItem
    }
  }
})
