
import { defineComponent, ref } from 'vue'
import { wechatAuth } from '@/service/loginService'
import { setToken } from '@/utils/auth'
import WaveLoading from '@/components/waveLoading/index.vue'
import NotOpen from './components/notOpen.vue'

export default defineComponent({
  name: 'RedirectPage',
  components: {
    WaveLoading,
    NotOpen
  },
  created() {
    const { code, appid } = this.$route.query
    this.wxLogin(code, appid)
  },
  setup() {
    const loading = ref(false)
    const showDialog = ref(false)
    return { loading, showDialog }
  },
  methods: {
    async wxLogin(code: any, appId: any) {
      this.loading = true
      try {
        const params = {
          appId: appId,
          code: code
        }
        const res = await wechatAuth(params)
        if (res.data.type === 'tempToken') {
          sessionStorage.setItem('tempToken', res.data.token)
          sessionStorage.setItem('appId', res.data.appId)
          this.$router.push({ path: '/loginForm', query: { type: 'temp' } })
        } else {
          setToken(res.data.token)
          this.$router.push('/home')
        }
      } catch (err) {
        this.showDialog = true
      } finally {
        this.loading = false
      }
    },
    cancelDialog() {
      this.showDialog = false
      const clientId = sessionStorage.getItem('clientId')
      this.$router.push({ path: '/login', query: { clientId: clientId } })
    }
  },
  beforeRouteLeave(to, from, next) {
    // debugger
    if (to.path === '/login' && from.path === '/redirect') {
      history.go(-2)
      next()
    } else {
      next()
    }
  }
})
