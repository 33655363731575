
import { ref, reactive } from 'vue'
import { Tab, Tabs } from 'vant'
import { defineComponent } from '@vue/runtime-core'
import TopCard from './modules/topCard/TopCard.vue'
import RatingInterpretation from './modules/RatingInterpretation/index.vue'
import NegativeInformation from './modules/NegativeInformation/index.vue'
import CompanyPortrait from './modules/Portrait/CompanyPortrait/index.vue'
import PersonPortrait from './modules/Portrait/PersonPortrait/PersonPortrait.vue'

import { ICompany } from '@/interfaces/companyReportType'
import { ICustomerInfo } from '@/interfaces/customerReportType'

import { getEnterprisePortrait } from '@/service/enterpriseService'
import { getPersonalPortrait } from '@/service/personalService'
import CompanyManagementPortrait from './modules/Portrait/companyManagementPortrait/index.vue'

export default defineComponent({
  name: 'ReportPage',
  components: {
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    TopCard,
    RatingInterpretation,
    NegativeInformation,
    CompanyPortrait,
    PersonPortrait,
    CompanyManagementPortrait
},
  setup() {
    const portraitActive = ref('company')
    let loading = ref(true)
    let activedIndex = ref(0);
    let showCompany = ref(false)
    let showCustomer = ref(false)

    // 企业画像
    let companyInfo = reactive<{ data: ICompany }>({
      data: {
        basicInfoVo: {
          actualCapital: 0,
          businessScope: '',
          cancelDate: '',
          cancelReason: '',
          companyOrgType: '',
          creditCode: '',
          establishTime: '',
          industry: '',
          legalPersonName: '',
          name: '',
          regCapital: 0,
          regInstitute: '',
          regLocation: '',
          regStatus: '',
          revokeDate: '',
          revokeReason: '',
          taxNumber: ''
        },
        shareholderVoList: [],
        ultimateBeneficiaryVoList: [],
        faithlessVoList: [],
        executionVoList: [],
        courtAnnouncementVoList: [],
        courtRegisterVoList: [],
        abnormalOperationVoList: [],
        illegalVoList: [],
        punishVoList: [],
        equityPledgeVoList: [],
        chattelMortgageVoList: []
      }
    })

    // 个人画像
    let customerInfo = reactive<{ data: ICustomerInfo }>({
      data: {
        behaviorRiskScore: 0,
        behaviorRiskScoreLevel: '',
        businessRelationship: '',
        creditRating: '',
        creditRatingScore: 0,
        dishonestyInfoList: [],
        executionInfoList: [],
        existHighRiskHistory: false,
        existHighRiskLoanBehavior: false,
        existMediumRiskHistory: false,
        existMiddleOrHighRiskLoanBehavior: false,
        highDebtPressure: false,
        idCard: '',
        incomeScore: 0,
        isOneselfTel: '',
        lowBehaviorLevel: false,
        mobile: '',
        name: '',
        nearlySevenDaysNum: 0,
        nearlySevenDaysOrgNum: 0,
        nearlySixMonthsNum: 0,
        nearlySixMonthsOrgNum: 0,
        nearlyThreeMonthsNum: 0,
        nearlyThreeMonthsOrgNum: 0,
        nearlyTwelveMonthsNum: 0,
        nearlyTwelveMonthsOrgNum: 0,
        telNetworkStatus: '',
        telPeriodPro: ''
      }
    })

    // 企业负面信息汇总
    const companyNegativeInfoContent = reactive<string[]>([])
    // 个人负面信息汇总
    const customerNegativeInfoContent = reactive<string[]>([])

    // 获取企业画像
    const companyNegativeFunc = async function () {
      const params = {
        keyword: sessionStorage.getItem('enterprise') || ''
      }
      if (params.keyword === '') {
        return
      }
      const res = await getEnterprisePortrait(params)
      if (res.code === '0') {
        companyInfo.data = res.data
        getCompanyContent()
        loading.value = false
        showCompany.value = true
      }
    }

    // 获取个人画像
    const customerNegativeFunc = async function () {
      const sessionCustomerData = sessionStorage.getItem('personalData')

      const params = sessionCustomerData && JSON.parse(sessionCustomerData)

      if (!params) {
        return
      }
      const res = await getPersonalPortrait(params)
      if (res.code === '0') {
        customerInfo.data = res.data
        getCustomerContent()
        loading.value = false
        showCustomer.value = true
      }
    }

    // 企业失信标签
    const getCompanyContent = function () {
      if (companyInfo.data.faithlessVoList.length > 0) {
        companyNegativeInfoContent.push('失信被执行')
      }
      if (companyInfo.data.executionVoList.length > 0) {
        companyNegativeInfoContent.push('被执行人')
      }
      if (companyInfo.data.courtAnnouncementVoList.length > 0) {
        companyNegativeInfoContent.push('作为被告开庭')
      }
      if (companyInfo.data.courtRegisterVoList.length > 0) {
        companyNegativeInfoContent.push('作为被告立案')
      }
      if (
        companyInfo.data.abnormalOperationVoList.length > 0 ||
        companyInfo.data.illegalVoList.length > 0 ||
        companyInfo.data.punishVoList.length > 0 ||
        companyInfo.data.equityPledgeVoList.length > 0 ||
        companyInfo.data.chattelMortgageVoList.length > 0
      ) {
        companyNegativeInfoContent.push('其他风险预警')
      }
    }

    // 个人失信标签
    const getCustomerContent = function () {
      if (customerInfo.data.lowBehaviorLevel) {
        customerNegativeInfoContent.push('金融信用评级低')
      }

      if (customerInfo.data.dishonestyInfoList.length > 0) {
        customerNegativeInfoContent.push('失信被执行')
      }
      if (customerInfo.data.executionInfoList.length > 0) {
        customerNegativeInfoContent.push('被执行人')
      }
      if (customerInfo.data.highDebtPressure) {
        customerNegativeInfoContent.push('债务压力高')
      }
      if (customerInfo.data.existMiddleOrHighRiskLoanBehavior) {
        customerNegativeInfoContent.push('中高风险借贷行为')
      }
    }
    companyNegativeFunc()
    customerNegativeFunc()
    return {
      loading,
      portraitActive,
      showCompany,
      showCustomer,
      companyInfo,
      customerInfo,
      companyNegativeInfoContent,
      customerNegativeInfoContent
    }
  }
})
