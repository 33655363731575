import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '@/views/home/index.vue'
import Report from '@/views/report/index.vue'
import LoginView from '@/views/login/index.vue'
import LoginForm from '@/views/login/login.vue'
import varifyCode from '@/views/login/varifyCode.vue'
import NotFound from '@/views/404/index.vue'
import WelCome from '@/views/welcome/index.vue'
import searchCompany from '@/views/searchCompany/index.vue'
import completInfo from '@/views/completInfo/index.vue'
import RedirectPage from '@/views/redirect/index.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView,
    meta: {
      title: '链信'
    }
  },
  {
    path: '/report',
    name: 'Report',
    component: Report,
    meta: {
      title: '供应链伙伴描绘'
    }
  },
  {
    path: '/welcome',
    name: 'welcompe',
    component: WelCome
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: {
      title: '链信'
    }
  },
  {
    path: '/loginForm',
    name: 'LoginForm',
    component: LoginForm,
    meta: {
      title: '短信登录'
    }
  },
  {
    path: '/varifyCode',
    name: 'varifyCode',
    component: varifyCode,
    meta: {
      title: '短信登录'
    }
  },
  {
    path: '/search',
    name: 'searchCompany',
    component: searchCompany,
    meta: {
      keepAlive: true,
      title: '选择企业'
    }
  },
  {
    path: '/completInfo',
    name: 'completInfo',
    component: completInfo,
    meta: {
      title: '信息补充'
    }
  },
  {
    path: '/redirect',
    name: 'RedirectPage',
    component: RedirectPage,
    meta: {
      title: '链信'
    }
  },
  { path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFound }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
