
import { defineComponent } from '@vue/runtime-core'
import CustomerCpllapseVue from '@/components/customerCollapse/CustomerCpllapse.vue'
import UltimateCard from './modules/card.vue'

export default defineComponent({
  name: 'UltimateBeneficiary',
  props: {
    activeName: Array,
    data: Array as () => { logo: string; name: string; percent: number }[]
  },
  components: {
    CustomerCpllapseVue,
    UltimateCard
  }
})
