
import { computed, ref } from 'vue'
import { defineComponent, nextTick } from '@vue/runtime-core'
import { Cell, Image as VanImage } from 'vant'

export default defineComponent({
  name: 'CustomerCell',
  components: {
    [Cell.name]: Cell,
    [VanImage.name]: VanImage
  },
  props: {
    title: String,
    value: [String, Number],
    labelWidth: Number,
    valueColor: String
  },
  setup(props) {
    const showOpen = ref(false)
    const changeBtn = ref(false)

    const labelItemWidth = computed(() => {
      return props.labelWidth
    })
    const valueItemColor = computed(() => {
      return props.valueColor
    })
    const textStyle = computed(() => {
      return {
        'max-height': changeBtn.value ? 'none' : `${1.8 * 4}em`
      }
    })

    nextTick(() => {
      let temp = document.getElementsByClassName('cell_value')[0]
      let clientWidth
      if (temp) {
        clientWidth = temp.clientWidth
      }
      const addWidth = props.value && props.value.toString().length * 13

      if (addWidth && clientWidth && addWidth / clientWidth > 4) {
        showOpen.value = true
      }
    })

    const changOpen = function () {
      changeBtn.value = !changeBtn.value
    }

    return {
      labelItemWidth,
      valueItemColor,
      textStyle,
      showOpen,
      changeBtn,
      changOpen
    }
  }
})
