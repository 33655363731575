
import { computed } from 'vue'
import { CellGroup } from 'vant'
import { defineComponent } from '@vue/runtime-core'
import CustomerCpllapseVue from '@/components/customerCollapse/CustomerCpllapse.vue'
import Cell from '@/components/customerCell/index.vue'

export type ICustomerBasicInfo = {
  name: string
  mobile: string
  idCard: string
  businessRelationship: string //1-法定代表人 2-实际控制人 3-股东 4-高管
  telPeriodPro: string //W-未知 A-3个月以内 B-3至6个月 C-6至12个月 D-12至24个月 E-24个月以上
  telNetworkStatus: string //手机在网状态 Y-正常使用 N-状态异常 W-未知
  isOneselfTel: string //是否本人手机号 Y-是 N-否 W-未知
}

export default defineComponent({
  name: 'CustomerBasicInfo',
  props: {
    activeName: Array,
    customerInfo: {
      type: Object as () => ICustomerBasicInfo,
      required: true
    }
  },
  components: {
    [CellGroup.name]: CellGroup,
    Cell,
    CustomerCpllapseVue
  },
  setup(props) {
    // 与企业关系
    const businessRelation = computed(() => {
      let temp
      switch (props.customerInfo.businessRelationship) {
        case '1':
          temp = '法定代表人'
          break
        case '2':
          temp = '实际控制人'
          break
        case '3':
          temp = '股东'
          break
        case '4':
          temp = '高管'
          break
        default:
          temp = ''
          break
      }
      return temp
    })

    // 在网时长
    const telPeriod = computed(() => {
      let temp
      switch (props.customerInfo.telPeriodPro) {
        case 'A':
          temp = '3个月以内'
          break
        case 'B':
          temp = '3至6个月'
          break
        case 'C':
          temp = '6至12个月'
          break
        case 'D':
          temp = '12至24个月'
          break
        case 'E':
          temp = '24个月以上'
          break
        default:
          temp = ''
          break
      }
      return temp
    })

    // 手机使用状态
    const useStatus = computed(() => {
      let temp
      switch (props.customerInfo.telNetworkStatus) {
        case 'Y':
          temp = '正常使用'
          break
        case 'N':
          temp = '状态异常'
          break
        case 'W':
          temp = '未知'
          break
        default:
          temp = ''
          break
      }
      return temp
    })

    // 是否本人
    const isSelf = computed(() => {
      let temp
      switch (props.customerInfo.isOneselfTel) {
        case 'Y':
          temp = '是'
          break
        case 'N':
          temp = '否'
          break
        case 'W':
          temp = '未知'
          break
        default:
          temp = ''
          break
      }
      return temp
    })
    return {
      businessRelation,
      telPeriod,
      useStatus,
      isSelf
    }
  }
})
