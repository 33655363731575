/*
 * @Author: aoqing 
 * @Date: 2021-11-30 11:19:07 
 * @Last Modified by: aoqing
 * @Last Modified time: 2022-01-13 18:08:38
 */
export const option = {
  backgroundColor: '#FBFBFB',
  legend: {
    itemGap: 10,
    itemWidth: 10,
    borderWidth: 1,
    borderColor: '#CACACA',
    borderRadius: 5,
    // width: 100,
    // height: 32,
    right: 40,
    top: 15,
    icon: 'circle',
    // lineStyle: {
    //  color: '#000000',
    //  width: 1,
    // },
    data: [
      {
        name: '笔数',
        itemStyle: {
          // borderColor: '#6590FF',
          // borderWidth: 2,
        },
        textStyle: {
          color: '#6590FF',
          fontSize: 11,
          width: 50,
          align: 'center',
        },
      },
      {
        name: '机构数',
        itemStyle: {
          // borderColor: '#ccc',
          // borderWidth: 2,
          // color: 'red',
        },
        textStyle: {
          fontSize: 11,
          color: '#659000',
          padding: [5, 0]
        },
      }
    ],
    selected: {
      '笔数': true,
      '机构数': false
    }
  },
  xAxis: {
    type: 'category',
    data: ['7天', '3个月', '6个月', '12个月'],
    axisTick: {
      show: false
    }
  },
  yAxis: {
    type: 'value',
  },
  barWidth: 12,
  series: [
    {
      data: [8, 14, 22, 44],
      type: 'bar',
      name: '笔数',
      barWidth: 12,
      itemStyle: {
        // barWidth: 12,
        color: '#6590FF',
        borderWidth: 12,
        borderRadius: [6, 6, 0, 0]
      }
    },
    {
      data: [8, 14, 22, 44],
      type: 'bar',
      name: '机构数',
      barWidth: 12,
      itemStyle: {
        // barWidth: 12,
        color: '#659000',
        borderWidth: 12,
        borderRadius: [6, 6, 0, 0]
      }
    }
  ]
};

// 经营收入趋势
export const manageTrendOption = {
  backgroundColor: '#FBFBFB',
  legend: {
    data: ['经营收入(万元)', '净利润(万元)', '经营收入增长率', '净利润增长率'],
    top: '5%',
  },
  grid: {
    left: 50,
    top: '15%',
    bottom: 50,
    right: 50
  },
  xAxis: [
    {
      type: 'category',
      data: ['2016年', '2017年', '2018年', '2019年', '2020年', '2021年', '2022年'],
      axisPointer: {
        type: 'shadow'
      }
    }
  ],
  yAxis: [
    {
      type: 'value',
      min: 0,
      max: 250,
      interval: 50,
      axisLabel: {
        formatter: '{value}'
      }
    },
    {
      type: 'value',
      min: 0,
      max: 25,
      interval: 5,
      axisLabel: {
        formatter: '{value}'
      }
    }
  ],
  series: [
    {
      name: '经营收入(万元)',
      type: 'bar',
      itemStyle: {
        barWidth: 12,
        color: '#6590FF',
        borderWidth: 12,
        borderRadius: [6, 6, 0, 0]
      },
      data: [
        2.0, 4.9, 7.0, 23.2, 25.6, 76.7, 135.6, 162.2, 32.6, 20.0, 6.4, 3.3
      ]
    },
    {
      name: '净利润(万元)',
      type: 'bar',
      itemStyle: {
        barWidth: 12,
        color: '#C7C7C7',
        borderWidth: 12,
        borderRadius: [6, 6, 0, 0]
      },
      data: [
        1.6, 5.6, 8.0, 29.4, 29.7, 72.7, 170.6, 152.2, 38.7, 28.8, 16.0, 23.3
      ]
    },
    {
      name: '经营收入增长率',
      type: 'line',
      yAxisIndex: 1,
      showSymbol: false,
      smooth: true,
      lineStyle: {
        color: '#FFA930',
        curveness: true,
      },
      data: [2.0, 2.2, 3.3, 4.5, 6.3, 10.2, 20.3, 23.4, 23.0, 16.5, 12.0, 6.2]
    },
    {
      name: '净利润增长率',
      type: 'line',
      showSymbol: false,
      lineStyle: {
        color: '#4EC0E7',
      },
      smooth: true,
      yAxisIndex: 1,
      data: [3.0, 1.2, 5.3, 7.5, 2.3, 11.2, 12.3, 22.4, 21.0, 18.5, 11.0, 8.2]
    }
  ]
};