
import { defineComponent, ref } from 'vue'
import { Button, Field } from 'vant'
import WaveLoading from '@/components/waveLoading/index.vue'
import NotOpen from './components/notOpen.vue'
import {
  mobileLogin,
  mobileLoginTemp,
  mobileLoginCode,
  mobileLoginCodeTemp
} from '@/service/loginService'
import { setToken } from '@/utils/auth'

export default defineComponent({
  name: 'LoginForm',
  components: {
    [Button.name]: Button,
    [Field.name]: Field,
    WaveLoading,
    NotOpen
  },
  setup() {
    const varifyCode = ref('')
    const mobile = ref('')
    const verifyId = ref('')
    const disabled = ref(true)
    const isFocus = ref(false)
    const loading = ref(false)
    const showDialog = ref(false)
    const type = ref(false)
    const focusEvent = () => {
      isFocus.value = true
    }
    const blurEvent = () => {
      isFocus.value = false
    }
    const retry = ref(true)
    const timer = ref(0)
    const interval = ref(0)
    return {
      varifyCode,
      disabled,
      focusEvent,
      isFocus,
      blurEvent,
      loading,
      showDialog,
      retry,
      timer,
      interval,
      type,
      mobile,
      verifyId
    }
  },
  created() {
    const $i = this.$route.query
    const { type } = $i
    if (type) {
      this.type = true
      document.title = '绑定手机号'
    }
    this.retry = false
    this.timer = 59
    this.interval = setInterval(() => {
      this.timer--
      if (this.timer < 1) {
        this.timer = 0
        clearInterval(this.interval)
        this.retry = true
      }
    }, 1000)
    const codeData = JSON.parse(sessionStorage.getItem('codeData') || '')
    this.mobile = codeData.mobile
    this.verifyId = codeData.verifyId
  },
  methods: {
    fieldInput(v: string) {
      if (v.length === 6) {
        this.loading = true
        this.login()
      }
    },
    async retrySend() {
      try {
        if (this.type) {
          const appId = sessionStorage.getItem('appId') || ''
          const params = {
            appId: appId,
            mobile: this.mobile,
            tempToken: sessionStorage.getItem('tempToken')
          }
          const res = await mobileLoginCodeTemp(params)
          this.verifyId = res.data
          this.retryClick()
        } else {
          const params = {
            mobile: this.mobile
          }
          const res = await mobileLoginCode(params)
          this.verifyId = res.data
          this.retryClick()
        }
      } catch (err) {
        this.loading = false
      }
    },
    retryClick() {
      this.retry = false
      this.timer = 59
      this.interval = setInterval(() => {
        this.timer--
        if (this.timer < 1) {
          this.timer = 0
          clearInterval(this.interval)
          this.retry = true
        }
      }, 1000)
    },
    async login() {
      try {
        if (this.type) {
          const appId = sessionStorage.getItem('appId') || ''
          const params = {
            appId: appId,
            mobile: this.mobile,
            verifyCode: this.varifyCode,
            verifyId: this.verifyId,
            tempToken: sessionStorage.getItem('tempToken')
          }
          const res = await mobileLoginTemp(params)
          if (res.code === '1140160004') {
            this.showDialog = true
            return
          }
          setToken(res.data)
          this.$router.push('/home')
        } else {
          const params = {
            mobile: this.mobile,
            verifyCode: this.varifyCode,
            verifyId: this.verifyId
          }
          const res = await mobileLogin(params)
          if (res.code === '1140160004') {
            this.showDialog = true
            return
          }
          setToken(res.data)
          this.$router.push('/home')
        }
      } catch (err) {
        this.loading = false
      }
    },
    cancelDialog() {
      this.showDialog = false
    }
  }
})
