import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-720534bf"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pie polar" }
const _hoisted_2 = { class: "pie" }
const _hoisted_3 = { class: "pie" }
const _hoisted_4 = { class: "pie" }
const _hoisted_5 = { class: "pie" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_chart = _resolveComponent("v-chart")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_v_chart, {
        class: "chart",
        option: _ctx.piePption
      }, null, 8, ["option"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_v_chart, {
        class: "chart",
        option: _ctx.gauge
      }, null, 8, ["option"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_v_chart, {
        class: "chart",
        option: _ctx.income
      }, null, 8, ["option"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_v_chart, {
        class: "chart",
        option: _ctx.cedit
      }, null, 8, ["option"])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_v_chart, {
        class: "chart",
        option: _ctx.line
      }, null, 8, ["option"])
    ])
  ]))
}