
import { defineComponent, ref, watch } from 'vue'
import { Button, Form, Field, CellGroup, Image as VanImage, Dialog } from 'vant'

export default defineComponent({
  name: 'CancelDialogComponent',
  props: {
    active: Boolean,
    errMsg: String
  },
  components: {
    [Button.name]: Button,
    [Form.name]: Form,
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
    [VanImage.name]: VanImage,
    [Dialog.Component.name]: Dialog.Component
  },
  setup(props, { emit }) {
    const show = ref(false)

    watch(
      () => props.active,
      () => {
        show.value = props.active
      }
    )

    const handleClick = () => {
      emit('cancelDialog')
    }

    return { show, close, handleClick }
  },
  methods: {}
})
