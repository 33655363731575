
import { defineComponent, ref } from 'vue'
import { Button, Image as VanImage } from 'vant'
import { wechatClient } from '@/service/loginService'
import { removeToken } from '@/utils/auth'

export default defineComponent({
  name: 'LoginPage',
  components: {
    [VanImage.name]: VanImage,
    [Button.name]: Button
  },
  created() {
    removeToken()
    this.isWx()
  },
  setup() {
    const wx = ref(false)
    const appId = ref('')
    const authScope = ref('')
    const componentAppId = ref('')
    return { wx, appId, authScope, componentAppId }
  },
  methods: {
    isWx() {
      if (navigator.userAgent.toLowerCase().indexOf('micromessenger') !== -1) {
        const clientId = sessionStorage.getItem('clientId')
        if (clientId) {
          this.wx = true
        } else {
          this.wx = false
        }
      } else {
        this.wx = false
      }
    },
    wxLogin() {
      if (this.appId) {
        sessionStorage.setItem('appId', this.appId)
        const redirectUri = encodeURIComponent(window.location.origin + '/redirect')
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${this.appId}&redirect_uri=${redirectUri}&response_type=code&scope=${this.authScope}&state=STATE&component_appid=${this.componentAppId}#wechat_redirect`
      }
    },
    phoneLogin() {
      console.log('==========>')
      this.$router.push('/loginForm')
    },
    async init() {
      try {
        const clientId = sessionStorage.getItem('clientId')
        const params = {
          clientId: clientId || ''
        }
        const res = await wechatClient(params)
        this.appId = res.data.appId
        this.authScope = res.data.authScope
        this.componentAppId = res.data.componentAppId
        this.wxLogin()
      } catch (err) {
        console.log(err)
      }
    }
  }
})
