
import { defineComponent, reactive, ref } from 'vue'
import { Button, Field, Notify } from 'vant'
import WaveLoading from '@/components/waveLoading/index.vue'
import NotOpen from './components/notOpen.vue'
import { mobileLoginCode, mobileLoginCodeTemp } from '@/service/loginService'

export default defineComponent({
  name: 'LoginForm',
  components: {
    [Button.name]: Button,
    [Field.name]: Field,
    [Notify.name]: Notify,
    WaveLoading,
    NotOpen
  },
  setup() {
    const phone = ref('')
    const disabled = ref(true)
    const isFocus = ref(false)
    const loading = ref(false)
    const showDialog = ref(false)
    const type = ref(false)
    const loadingStyle = reactive({
      'background-color': '#fff',
      width: '10px',
      height: '10px'
    })
    const focusEvent = () => {
      isFocus.value = true
    }
    const blurEvent = () => {
      isFocus.value = false
    }
    const fieldInput = (v: string) => {
      if (v.length === 11) {
        disabled.value = false
      } else {
        disabled.value = true
      }
    }
    return {
      phone,
      disabled,
      focusEvent,
      isFocus,
      blurEvent,
      fieldInput,
      loading,
      loadingStyle,
      showDialog,
      type
    }
  },
  created() {
    const $i = this.$route.query
    const { type } = $i
    if (type) {
      this.type = true
      document.title = '绑定手机号'
    }
  },
  methods: {
    getCode() {
      const phoneValid = /^[1][3-9][0-9]{9}$/
      if (phoneValid.test(this.phone)) {
        this.loading = true
        this.sendCode()
      } else {
        return Notify({ type: 'danger', message: '请先输入正确的手机号' })
      }
    },
    async sendCode() {
      try {
        if (this.type) {
          const appId = sessionStorage.getItem('appId') || ''
          const params = {
            appId: appId,
            mobile: this.phone,
            tempToken: sessionStorage.getItem('tempToken')
          }
          const res = await mobileLoginCodeTemp(params)
          if (res.code === '1140160004') {
            this.showDialog = true
            this.loading = false
            return
          }
          const codeData = {
            verifyId: res.data,
            mobile: this.phone
          }
          sessionStorage.setItem('codeData', JSON.stringify(codeData))
          this.$router.push({
            path: '/varifyCode',
            query: { type: 'temp' }
          })
        } else {
          const params = {
            mobile: this.phone
          }
          const res = await mobileLoginCode(params)
          if (res.code === '1140160004') {
            this.showDialog = true
            this.loading = false
            return
          }
          const codeData = {
            verifyId: res.data,
            mobile: this.phone
          }
          sessionStorage.setItem('codeData', JSON.stringify(codeData))
          this.$router.push('/varifyCode')
        }
      } catch (err: any) {
        this.loading = false
      }
    },
    cancelDialog() {
      this.showDialog = false
    }
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    console.log(to)
    if (to.path === '/redirect') {
      next('/login')
    } else {
      next()
    }
  }
})
